<template>
     <div id="preloader">
          <div class="jumper">
               <div></div>
               <div></div>
               <div></div>
          </div>
     </div>
</template>

<script>
     export default {
          name: 'App',
          methods:{
               getPreload() {
                    setTimeout(function() {
                         var preload = document.querySelector("#preloader")

                         preload.style.visibility = "hidden"
                    }, 1600)
               }
          },
          created: function() {
               this.getPreload()
          }
     }
</script>

<style scoped>
     #preloader {
          overflow: hidden;
          background-image: linear-gradient(135deg, #F7350F 0%, #12169C 100%);
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: fixed;
          z-index: 9999;
          color: #fff;
     }

     #preloader .jumper {
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: block;
          position: absolute;
          margin: auto;
          width: 50px;
          height: 50px;
     }

     #preloader .jumper > div {
          background-color: #fff;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          position: absolute;
          opacity: 0;
          width: 50px;
          height: 50px;
          -webkit-animation: jumper 1s 0s linear infinite;
          animation: jumper 1s 0s linear infinite;
     }

     #preloader .jumper > div:nth-child(2) {
          -webkit-animation-delay: 0.33333s;
          animation-delay: 0.33333s;
     }

     #preloader .jumper > div:nth-child(3) {
          -webkit-animation-delay: 0.66666s;
          animation-delay: 0.66666s;
     }

     @-webkit-keyframes jumper {
          0% {
               opacity: 0;
               -webkit-transform: scale(0);
               transform: scale(0);
          }
          5% {
               opacity: 1;
          }
          100% {
               -webkit-transform: scale(1);
               transform: scale(1);
               opacity: 0;
          }
     }

     @keyframes jumper {
          0% {
               opacity: 0;
               -webkit-transform: scale(0);
               transform: scale(0);
          }
          5% {
               opacity: 1;
          }
          100% {
               opacity: 0;
          }
     }
</style>
