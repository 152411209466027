<template>
  <div id="app" class="content">
    <PreloadComponent></PreloadComponent>

    <CustomizerComponent></CustomizerComponent>
  </div>
</template>

<script>
  import PreloadComponent from './components/preload.vue'
  import CustomizerComponent from './components/customizer.vue'

  export default {
    name: 'App',
    components: {
      PreloadComponent,
      CustomizerComponent
    },
    created () {
      document.title = "® GHG CAPITAL CONSTRUCTION AND REMODELING";
    }
  }
</script>

<style scoped>
</style>
