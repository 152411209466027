<template>
  <div>
    <MenuComponent></MenuComponent>

    <div id="welcome">
      <div class="welcome-area">
        <div class="header-text">
          <div class="container">
            <div class="row">
              <div class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 col-md-12 col-sm-12">
                <h1>
                  "Your Make <strong>Readies</strong> <br>in <strong>good hands</strong>"
                </h1>

                <p>
                  Take a look at our projects and be encouraged to work side by side with our team of experts
                </p>

                <a href="#features">Discover More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main id="main">
      <AboutUsComponent></AboutUsComponent>

      <ServicesComponent></ServicesComponent>

      <FeaturesComponent></FeaturesComponent>

      <CardComponent></CardComponent>

      <!-- <PortfolioComponent></PortfolioComponent> -->

      <FaqComponent></FaqComponent>

      <ContactComponent></ContactComponent>

    </main>

    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
  import MenuComponent from './menu.vue'
  import AboutUsComponent from './aboutus.vue'
  import ServicesComponent from './services.vue'
  import FeaturesComponent from './features.vue'
  import CardComponent from './cta.vue'
  import FaqComponent from './faq.vue'
  import ContactComponent from './contact.vue'
  import FooterComponent from './footer.vue'

  export default {
    name: 'App',
    components: {
      MenuComponent,
      AboutUsComponent,
      ServicesComponent,
      FeaturesComponent,
      CardComponent,
      FaqComponent,
      ContactComponent,
      FooterComponent
    }
  }
</script>

<style scoped>
  #welcome {
    background-color: #12169C;
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 15% 100%);
  }

  .welcome-area {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/images/backgrounds/bg-2.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover; 
    height: 100vh;
    background-color: #12169C;
  }

  .welcome-area .header-text {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    text-align: center;
    width: 100%;
  }

  .welcome-area .header-text h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 1.4px;
    margin-bottom: 30px;
    color: #fff;
    text-transform: uppercase;
  }

  .welcome-area .header-text p {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    line-height: 28px;
    letter-spacing: 0.25px;
    margin-bottom: 40px;
    position: relative;
  }

  .welcome-area .header-text a {
    background: #F7350F;
    color: #FFF;
    text-decoration: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    padding: 15px 15px;
    border-radius: 30px 30px;
  }

  .welcome-area .header-text a:hover {
    background: #12169C;
  }

  @media (max-width: 991px) {
    .welcome-area .header-text {
      top: 65% !important;
      transform: perspective(1px) translateY(-60%) !important;
      text-align: center;
    }
    .welcome-area .header-text h1 {
      text-align: center;
      color: #fff;
      margin-bottom: 15px;
    }
    .welcome-area .header-text h1 span {
      color: #fff;
    }
    .welcome-area .header-text p {
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 820px) {
    .welcome-area .header-text {
      top: 65% !important;
      transform: perspective(1px) translateY(-60%) !important;
    }
    .welcome-area .header-text h1 {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 15px;
    }
    .welcome-area .header-text p {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 765px) {
    #welcome {
      background: #12169C;
      clip-path: none;
    }

    .welcome-area {
      margin-bottom: 100px;
      background-position: center center;
    }
    .welcome-area .header-text {
      top: 50% !important;
      transform: perspective(1px) translateY(-50%) !important;
      text-align: center;
    }
    .welcome-area .header-text .buttons {
      display: none;
    }
    .welcome-area .header-text h1 {
      font-weight: 600;
      font-size: 24px !important;
      line-height: 30px !important;
      margin-bottom: 30px !important;
    }
    .welcome-area .header-text h1 span {
      color: #fff;
    }
    .welcome-area .header-text p {
      text-align: center;
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 40px;
    }
  }

  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
</style>
