<template>
     <section id="contact" class="contact" style="background: #12169C; color: #FFF;">
          <div class="container">
               <div class="section-title">
                    <h2>Contact</h2>

                    <h3>Contact <span>Us</span></h3>
                    
                    <p>Do not look at the time before calling, we are here to serve you 24 hours a day.</p>
               </div>

               <div>
                    <iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d220448.69730932315!2d-97.89348506010107!3d30.307982708340784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b599a0cc032f%3A0x5d9b464bd469d57a!2sAustin%2C%20Texas%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1652849512755!5m2!1ses-419!2smx" allowfullscreen loading="lazy" frameborder="0"></iframe>
               </div>

               <div class="row mt-5">
                    <div class="col-lg-5">
                         <div class="info">
                              <!-- <div class="address">
                                   <b-icon icon="cursor" aria-hidden="true" style=""></b-icon>
                                   <h4 style=" color: #FFF;">Location:</h4>
                                   <p style=" color: #FFF;">6407 Springdale Rd Apt 2307, Austin, Texas , 78723</p>
                              </div> -->

                              <div class="email">
                                   <b-icon icon="envelope" aria-hidden="true" style=""></b-icon>
                                   <h4 style=" color: #FFF;">Email:</h4>
                                   <p style=" color: #FFF;">hello@ghg-capital.com</p>
                              </div>

                              <div class="phone">
                                   <b-icon icon="telephone-fill" aria-hidden="true" style=""></b-icon>
                                   <h4 style=" color: #FFF;">Call:</h4>
                                   <p style=" color: #FFF;">512 995 8399</p>
                              </div>
                         </div>
                    </div>

                    <div class="col-lg-7 mt-5 mt-lg-0">
                         <form class="php-email-form" novalidate="novalidate" ref="form" @submit.prevent="sendEmail" style="background: #12169C; color: #FFF;">
                              <div class="row">
                                   <div class="col-md-6 form-group">
                                        <input type="text" class="form-control" v-model="name" name="name" id="name" placeholder="Your Name" required>
                                   </div>

                                   <div class="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" class="form-control" v-model="email" name="email" id="email" placeholder="Your Email" required>
                                   </div>
                              </div>

                              <div class="row mt-3">
                                   <div class="col-md-6 form-group">
                                        <input type="text" class="form-control" v-model="phone" name="phone" id="phone" placeholder="Your Phone Number" required>
                                   </div>

                                   <div class="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="text" class="form-control" v-model="company" name="company" id="company" placeholder="Your Company" required>
                                   </div>
                              </div>

                              <div class="form-group mt-3">
                                   <input type="text" class="form-control" v-model="subject" name="subject" id="subject" placeholder="Subject" required>
                              </div>

                              <div class="form-group mt-3">
                                   <textarea class="form-control" v-model="description" name="description" id="description" rows="5" placeholder="Description" required></textarea>
                              </div>

                              <div class="my-3">
                                   <div class="loading">Loading</div>
                                   <div class="error-message"></div>
                                   <div class="sent-message">Your message has been sent. Thank you!</div>
                              </div>

                              <b-alert
                                   :show="dismissCountDown"
                                   fade
                                   variant="danger"
                                   @dismiss-count-down="countDownChanged"
                              >
                                   {{ messageValidation }}
                              </b-alert>

                              <div class="text-center">
                                   <b-button variant="default" type="submit">Enviar</b-button>
                              </div>
                         </form>
                    </div>
               </div>
          </div>
     </section>
</template>

<script>
     import emailjs from '@emailjs/browser';

     export default {
          name: 'Contact',

          data() {
               return {
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    subject: '',
                    description: '',

                    dismissSecs: 5,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,

                    messageValidation: 'Please complete all requested information to continue'
               }
          },

          methods: {
               sendEmail(e) {
                    e.preventDefault()

                    if(!this.description || !this.name || !this.company || !this.email || !this.phone || !this.subject) {
                         this.showAlert()

                         return
                    }

                    const Toast = this.$swal.mixin({
                         toast: true,
                         position: 'top-end',
                         showConfirmButton: false,
                         timer: 3000,
                         timerProgressBar: true,
                         didOpen: (toast) => {
                              toast.addEventListener('mouseenter', this.$swal.stopTimer)
                              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                         }
                    })

                    emailjs.sendForm(
                         'service_gttnib9', 
                         'template_q1q2umo', 
                         this.$refs.form, 
                         '0JbPhVqaBGGzZVnMV'
                    ).then((result) => {
                         
                         console.log('200', result.text);

                         Toast.fire({
                              icon: 'success',
                              title: 'Mensaje enviado'
                         })

                         // Reset form field
                         this.name = '',
                         this.company = '',
                         this.email = '',
                         this.phone = '',
                         this.subject = '',
                         this.description = ''
                    }, (error) => {

                         console.log('500', error.text);

                         Toast.fire({
                              icon: 'error',
                              title: 'No se pudo enviar el mensaje'
                         })
                    });
               },

               countDownChanged(dismissCountDown) {
                    this.dismissCountDown = dismissCountDown
               },

               showAlert() {
                    this.dismissCountDown = this.dismissSecs
               },

               isNumber: function(evt) {
                    evt = (evt) ? evt : window.event
                    var charCode = (evt.which) ? evt.which : evt.keyCode

                    if (charCode < 48 || charCode > 57) {
                         evt.preventDefault()
                    } else {
                         return true;
                    }
               }
          }
     }
</script>

<style scoped>
     .contact .section-title {
          background: #12169C;
          color: #FFF;
          text-align: center;
          padding-bottom: 30px;
     }

     .contact .section-title h2 {
          font-size: 13px;
          letter-spacing: 1px;
          font-weight: 800;
          padding: 8px 20px;
          line-height: 1;
          margin: 0;
          background: #F7350F;
          display: inline-block;
          text-transform: uppercase;
          border-radius: 50px;
          color: #FFF;
     }

     .contact .section-title h3 {
          margin: 15px 0 0 0;
          font-size: 32px;
          font-weight: 700;
     }

     .contact .section-title h3 span {
          color: #F7350F;
     }

     .contact .section-title p {
          margin: 15px auto 0 auto;
          font-weight: 600;
     }

     .contact .info {
          width: 100%;
          background: #12169C;
     }

     .contact .info svg {
          color: #f7350f;
          float: left;
          width: 44px;
          height: 44px;
          background: #FFF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          transition: all 0.3s ease-in-out;
          padding: 7.5px 7.5px;
     }

     .contact .info h4 {
          padding: 0 0 0 60px;
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 5px;
          color: #493c3e;
     }

     .contact .info p {
          padding: 0 0 0 60px;
          margin-bottom: 0;
          font-size: 14px;
          color: #816a6e;
     }

     .contact .info .email, .contact .info .phone {
          margin-top: 40px;
     }

     .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
          background: #f7350f;
          color: #fff;
     }

     .contact .php-email-form {
          width: 100%;
          background: #fff;
     }

     .contact .php-email-form .form-group {
          padding-bottom: 8px;
     }

     .contact .php-email-form .error-message {
          display: none;
          color: #fff;
          background: #ed3c0d;
          text-align: left;
          padding: 15px;
          font-weight: 600;
     }

     .contact .php-email-form .error-message br + br {
          margin-top: 25px;
     }

     .contact .php-email-form .sent-message {
          display: none;
          color: #fff;
          background: #18d26e;
          text-align: center;
          padding: 15px;
          font-weight: 600;
     }

     .contact .php-email-form .loading {
          display: none;
          background: #fff;
          text-align: center;
          padding: 15px;
     }

     .contact .php-email-form .loading:before {
          content: "";
          display: inline-block;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin: 0 10px -6px 0;
          border: 3px solid #18d26e;
          border-top-color: #eee;
          -webkit-animation: animate-loading 1s linear infinite;
          animation: animate-loading 1s linear infinite;
     }

     .contact .php-email-form input, .contact .php-email-form textarea {
          border-radius: 20px;
          box-shadow: none;
          font-size: 14px;
     }

     .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
          border-color: #f7350f;
     }

     .contact .php-email-form input {
          height: 44px;
     }

     .contact .php-email-form textarea {
          padding: 10px 12px;
     }

     .contact .php-email-form button[type=submit] {
          background: #f7350f;
          border: 0;
          padding: 10px 28px;
          color: #fff;
          transition: 0.4s;
          border-radius: 50px;
     }

     .contact .php-email-form button[type=submit]:hover {
          background: #d01d3f;
     }

     @-webkit-keyframes animate-loading {
          0% {
               transform: rotate(0deg);
          }
          100% {
               transform: rotate(360deg);
          }
     }

     @keyframes animate-loading {
          0% {
               transform: rotate(0deg);
          }
          
          100% {
               transform: rotate(360deg);
          }
     }
</style>